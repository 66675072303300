import { scanFile } from "../pdfscan";
import { verifyCredentialByExternal, verifyCredentialByVersionUid } from "./ApiCommands";
import { getSha256DocumentHash } from "./getDocumentHash";

export async function retrieve(dropFile: any): Promise<any> {
    const retrieved = {
        qr: '',
        certVersionId: '',
        certId: '',
        qrFileHash: '',
        equalHash: false,
        skipCredentialVerif: false,
    };
    const verificationData = {
        documentUid: '',
        fileHash: '',
        fileName: '',
        originalFileHash: '',
        timestamp: '',
        userUid: '',
    };
    let resultBody = { status: 'ok', message: 'File uploaded successfully', retrieved, verificationData };
    try {
        const retrievedQR: string | null = await scanFile(dropFile);
        const blob = new Response(dropFile);
        const blobBff = await blob.blob();
        const file = await blobBff.arrayBuffer();
        const qrFileHash = await getSha256DocumentHash(file);
        const credentialVersionId = retrievedQR!.substring(retrievedQR!.lastIndexOf('/') + 1);
        await verifyCredentialByVersionUid(credentialVersionId)
            .then((res) => {
                const equalHash = res.fileHash === qrFileHash;
                resultBody = {
                    ...resultBody,
                    retrieved: {
                        qr: retrievedQR!,
                        certId: res.data?.credentialSubject?.id,
                        certVersionId: credentialVersionId,
                        qrFileHash: qrFileHash,
                        equalHash: equalHash,
                        skipCredentialVerif: false,
                    },
                };
            })
            .catch(async (error) => {
                await verifyCredentialByExternal(credentialVersionId, qrFileHash).then((res) => {
                    const equalHash = res.fileHash === qrFileHash;
                    resultBody = {
                        ...resultBody,
                        retrieved: {
                            qr: retrievedQR!,
                            certId: res.documentUid,
                            certVersionId: credentialVersionId,
                            qrFileHash: qrFileHash,
                            equalHash: equalHash,
                            skipCredentialVerif: true,
                        },
                        verificationData: res,
                    };
                });
            });        
    } catch (e: any) {
        let msg = '';
        if (e?.name === "InvalidPDFException") {
            msg = "Invalid PDF";
        } else if (e instanceof Event) {
            msg = "Invalid Image";
        } else {
            msg = "we can't access the file";
        }
        resultBody = {
            status: 'fail', message: msg, retrieved, verificationData,
        };
    }

    return resultBody;
}
